html {
    font-size: 1vw;
}

html,
body {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #FFF;
    color: #000;
    font-family: "josefin_slab", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    margin: 0;
    padding: 0;
    text-align: center;
}


.logo {
    max-width: 30vw;
    border-radius: 2px;
}

.navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 400px;
    margin: 8vh 0 0;
}

.navigation--link {
    font-size: 2rem;
    font-weight: 100;
    color: #7B2227;
}

.navigation--link:visited {
    color: #7B2227;
}

@media (max-width: 600px) {
    html {
        font-size: 3vw;
    }

    .logo {
        max-width: 70vw;
    }
}
